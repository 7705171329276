import { useTranslation } from "react-i18next";
import { AnimationOnScroll } from "react-animation-on-scroll";

const HowItWork = () => {
  const { i18n } = useTranslation();
  return (
    <div class="bg-cover bg-center h-full bg-no-repeat bg-[url('assets/bg-howitwork.png')]">
      <AnimationOnScroll animateIn={"animate__zoomIn"}>
        <div class=" top-0 left-0 w-full h-full">
          {i18n.language === "en" ? (
            <video
              src="https://pools-mall.s3.ap-southeast-1.amazonaws.com/videos/MotionMainnet_EN.mp4"
              autoPlay
              muted
              loop
            />
          ) : i18n.language === "vi" ? (
            <video
              src="https://pools-mall.s3.ap-southeast-1.amazonaws.com/videos/MotionMainnet_VN.mp4"
              autoPlay
              muted
              loop
            />
          ) : (
            <video
              src="https://pools-mall.s3.ap-southeast-1.amazonaws.com/videos/MotionMainnet_KR.mp4"
              autoPlay
              muted
              loop
            />
          )}
        </div>
      </AnimationOnScroll>
    </div>
  );
};
export default HowItWork;
