/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import fb_icon from "assets/icons/Social Media/fb.svg";
import twitter from "assets/icons/Social Media/twitter.svg";
import insIcon from "assets/icons/Social Media/ins.svg";
import titok from "assets/icons/Social Media/titok.svg";
import { useTranslation } from "react-i18next";
import useWindowSize from "hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import dropdownIcon from "assets/icon/dropdown.svg";
import emailjs from 'emailjs-com';

const YOUR_SERVICE_ID = 'service_frno125';
const YOUR_TEMPLATE_ID = 'template_f5zzpih';
const YOUR_USER_ID = 'SKDSvkpfXo-o3P0J9'



const Footer = () => {
  const { t } = useTranslation();
  const width = useWindowSize();
  const { hash } = useLocation();
  const isActive = (item) => {
    if (hash === item.href) return true;
  };
  const [open, setOpen] = useState(false);
  const community = [
    {
      name: "Facebook",
      link: "https://www.facebook.com/profile.php?id=100086429633297",
    },
    {
      name: "Telegram",
      link: "https://t.me/Poolsclub",
    },
    {
      name: "Discord",
      link: "https://discord.com/invite/sD4CbW4Ys8",
    },
    {
      name: "Twitter",
      link: "https://twitter.com/pools_club",
    },
  ];
  const Explorer = [
    {
      name: "POOLS Scan",
      link: " https://scan.poolsmobility.com/",
    },
    {
      name: "POOLS Phone",
      link: " https://poolsmobility.com",
    },
    {
      name: "POOLS Mall",
      link: "https://mall.poolsmobility.com",
    },
    {
      name: "POOLS Wallet",
      link: "https://wallet.poolsmobility.com",
    },
    {
      name: "POOLS Marketplace",
      link: "https://nftmarket.poolsmobility.com",
    },
  ];
  const handleChangeEcosystem = () => {
    const elem = document.activeElement;
    if (elem) {
      elem?.blur();
    }
  };

  // send mail
  const [email, setEmail] = useState('')
  const [checkEmail, setCheckEmail] = useState(false)
  const [mailSussce, setMailSussce] = useState(false)
  const form = useRef();
  let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  const sendEmail = (e) => {
    e.preventDefault();
    if (!filter.test(email)) {
      setCheckEmail(true);
      setMailSussce(false)
      return;
    } else {
      emailjs.sendForm(`${YOUR_SERVICE_ID}`, `${YOUR_TEMPLATE_ID}`, form.current, `${YOUR_USER_ID}`)
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
      setMailSussce(true)
      setCheckEmail(false)
      setEmail("");
    }
  }

  useEffect(() => {
    if (mailSussce === true) {
      setTimeout(() => {
        setMailSussce(false)
        //setCheckEmail(false)
      }, 3000)
    }
    if (checkEmail === true) {
      setTimeout(() => {
        setCheckEmail(false)
      }, 3000)
    }
  }, [mailSussce, checkEmail])

  



  return (
    <footer className="p-10 dark:bg-[#000000] bg-[#ffff] dark:text-[#fff] text-[#000]">
      <form ref={form} onSubmit={sendEmail}>
        <div className="max-w-[1390px] w-full mx-auto footer gap-[20px]">
          <div className="w-full">
            <div className="w-full flex sm:block justify-center mb-4">
              <span className="">
                <img src="/POOLS_LOGO.svg" alt="Pools logo" />
              </span>
            </div>

            <div className="hidden md:block ">{t("footer.play_ground_text")}</div>
            <div className="hidden md:flex gap-4 ">
              <span>{t("footer.copy_right")}{t("footer.reserved")} </span>
              {/* <span>{t("footer.reserved")}</span> */}
            </div>
          </div>

          <div className="grid justify-center gap-[20px] md:gap-[20px]  md:justify-between flex-wrap w-full md:flex  ">
            <div class="hidden md:block ">
              <a
                href="#community"
                className="link link-hover dark:text-[#fff] text-[#000] text-[14px] md:text-[20px] font-[500] "
              >
                {t("footer.community")}
              </a>
              {community.map((item) => (
                <p class="pt-3 ">
                  <a href={item.link} target={"_blank"} rel="noreferrer">
                    {item.name}
                  </a>
                </p>
              ))}
            </div>
            <div class="dropdown dropdown-center md:dropdown-right dropdown-bottom  block md:hidden ">
              <label tabindex="0" class="cursor-pointer ">
                <div
                  className={`  font-[500] text-[16px] leading-[24px]  hover:text-main_color relative
                ${isActive(`Ecosystem`)
                      ? ` before:absolute before:bg-gradient-to-r from-yellow-400 to-transparent before:rounded-[5px] before:bottom-0 before:w-full before:h-[2px] text-main_color before:transform-[matrix(1, 0, 0, -1, 0, 0)] transition-[all 0.35s ease-in] `
                      : `dark:text-[#fff] text-[#000]`
                    } `}
                >
                  <div className="w-full flex items-center ">
                    <a
                      // href="#community"
                      className="link link-hover dark:text-[#fff] text-[#000] text-[14px] md:text-[20px] font-[500] "
                    >
                      {t("footer.community")}
                    </a>
                    <img
                      src={dropdownIcon}
                      alt=""
                      class="h-[12px] w-[9px] ml-1 dark:brightness-[100%] brightness-0"
                    />
                  </div>
                </div>
              </label>
              <ul
                tabindex="0"
                class="dropdown-content menu p-2 shadow  rounded-box w-52 custom-dropdown bg-custom-2 border-[1px] border-[#111111] divide-y"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)",
                  boxShadow: "0px 0px 10px rgba(230, 203, 130, 0.25)",
                }}
              >
                {community.map((item, index) => (
                  <li
                    onClick={() => handleChangeEcosystem(item)}
                    key={index}
                    class="py-0.5"
                  >
                    <div className="flex gap-2 items-center">
                      <span className="font-[500] text-[#fff]">
                        <a href={item.link} target={"_blank"} rel="noreferrer">
                          {item.name}
                        </a>
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div class="hidden md:block">
              <a
                href=""
                className="link link-hover dark:text-[#fff] text-[#000] text-[14px] md:text-[20px] font-[500] pb-3"
              >
                {t("footer.explorer")}
              </a>

              <a href=" https://scan.poolsmobility.com/" target={"_blank"} rel="noreferrer">
                <p class="pb-3 pt-3 ">POOLS Scan</p>
              </a>
              <a href=" https://poolsmobility.com/" target={"_blank"} rel="noreferrer">
                <p class="pb-3 pt-1">POOLS Phone</p>
              </a>
              <a href=" https://mall.poolsmobility.com/" target={"_blank"} rel="noreferrer">
                <p class="pb-3">POOLS Mall</p>
              </a>
              <a href="https://wallet.poolsmobility.com" target={"_blank"} rel="noreferrer">
                <p class="pb-3">POOLS Wallet</p>
              </a>
              <a href="https://nftmarket.poolsmobility.com" target={"_blank"} rel="noreferrer">
                <p>NFT Marketplace</p>
              </a>
            </div>

            <div class="dropdown dropdown-center md:dropdown-right dropdown-bottom  block md:hidden ">
              <label tabindex="0" class="cursor-pointer">
                <div
                  className={`  font-[500] text-[16px] leading-[24px]  hover:text-main_color relative
                ${isActive(`Ecosystem`)
                      ? ` before:absolute before:bg-gradient-to-r from-yellow-400 to-transparent before:rounded-[5px] before:bottom-0 before:w-full before:h-[2px] text-main_color before:transform-[matrix(1, 0, 0, -1, 0, 0)] transition-[all 0.35s ease-in] `
                      : `text-[#ffff]`
                    } `}
                >
                  <div className="w-full flex items-center">
                    <a className="link link-hover dark:text-[#fff] text-[#000] text-[14px] md:text-[20px] font-[500] pb-3">
                      {t("footer.explorer")}
                    </a>
                    <img
                      src={dropdownIcon}
                      alt=""
                      class="h-[12px] w-[9px] ml-1 dark:brightness-[100%] brightness-0"
                    />
                  </div>
                </div>
              </label>
              <ul
                tabindex="0"
                class="dropdown-content menu p-2 shadow  rounded-box w-52 custom-dropdown bg-custom-2 border-[1px] border-[#111111] divide-y"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)",
                  boxShadow: "0px 0px 10px rgba(230, 203, 130, 0.25)",
                }}
              >
                {Explorer.map((item, index) => (
                  <li
                    onClick={() => handleChangeEcosystem(item)}
                    key={index}
                    class="py-0.5"
                  >
                    <div className="flex gap-2 items-center">
                      <span className="font-[500] text-[#fff]">
                        <a href={item.link} target={"_blank"} rel="noreferrer">
                          {item.name}
                        </a>
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div class="">
              <a
                href="https://pools-chain.gitbook.io/documentation/getting-started/background"
                target={"_blank"}
                className="link link-hover dark:text-[#fff] text-[#000] text-[14px] md:text-[20px] font-[500]"
              >
                {t("footer.docs")}
              </a>
            </div>
          </div>

          <div className="w-full">
            <div
              className="text-[14px] md:text-[20px] dark:text-[#fff] text-[#000] font-[500] leading-[150%] text-center md:text-right w-full"
              dangerouslySetInnerHTML={{
                __html: t("footer.text1", {
                  br: width > 768 ? "<br/>" : "",
                }),
              }}
            />
            <a className="font-[400] text-[16px] dark:text-[#fff] text-[#000] text-center md:text-right w-full">
              {t("footer.text2")}
            </a>

            <div
              className="flex gap-[20px] mt-[10px] w-full justify-end"
              id="contact"
            >
              <input
                className=" h-[3rem] max-w-[250px] w-full dark:bg-[#252525] bg-[#ffff] border border-gray-400 rounded-[8px] text-[18px] text-[#ffff] px-[10px] placeholder:text-[18px] focus:border-none focus:outline-none"
                placeHolder={t("footer.enter_mail")}
                type="email" name="user_email"
                value={email}
                onChange={(e) => { setEmail(e.target.value); }}
                onClick={() => { setMailSussce(false); setCheckEmail(false) }}
              />
              <button className=" h-[3rem] btn dark:border border-[#E6CB82] dark:bg-[#625637] bg-[#E6CB82] shadow-[0px 4px 44px rgba(143, 101, 59, 0.2)] rounded-[8px] dark:hover:bg-[#625637] hover:bg-[#c7af6e]"
                type="submit" value="Send"
              >
                {t("footer.send_mail")}
              </button>
            </div>

            {checkEmail === true
              ? (<div class=" pl-1 w-full flex p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Info</span>
                <div>
                  Please enter a valid email address.
                </div>
              </div>)
              : (<div></div>)
            }

            {mailSussce === true
              ? (<div class=" pl-1 w-full flex p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" id="elementSuccess" role="alert">
                <svg aria-hidden="true" class="flex-shrink-0 inline w-5 h-5 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Info</span>
                <div>
                  Email Sent Successfully !
                </div>
              </div>)
              : (<div></div>)

            }

          </div>

          <div className="w-full">
            <div className="md:hidden text-center w-full">
              {t("footer.play_ground_text")}
            </div>
            <div className="flex md:hidden text-[10px] md:text-[14px] w-full justify-center">
              <span>{t("footer.copy_right")}</span>
              <span>{t("footer.reserved")}</span>
            </div>
          </div>
        </div>
      </form>
    </footer>
  );
};

export default Footer;
