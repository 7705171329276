import Layout from 'components/layout'
import Home from 'pages/Home/Home';


const routes=[
    {
        path:"",
        element:<Layout/>,
        children: [
            {
                path: "/",
                element: <Home />,
            },
        ]
    }
]
export default routes;