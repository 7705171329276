import Container from "components/ui/Container";
import img_1 from "assets/icon/icon-green.png";
import img_2 from "assets/icon/icon-telegram.png";
import img_3 from "assets/icon/icon-twitter.png";
import img_4 from "assets/icon/icon-discord.png";
import img_5 from "assets/icon/icon-github.png";
import { useTranslation } from "react-i18next";
import { AnimationOnScroll } from "react-animation-on-scroll";
const HeroSection = () => {
  const { t } = useTranslation();
  return (
    <div class="bg-cover bg-bottom-1 bg-center md:bg-center h-full dark:bg-[url('assets/bg_m_hero.png')] bg-[url('assets/bg_m_hero_light.png')] dark:sm:bg-[url('assets/bg_Hero.png')] sm:bg-[url('assets/bg_hero_light.png')] dark:bg-[#000] bg-white ">
      <Container style={{ minHeight: "100vh" }}>
        <div class=" w-100 h-full  sm:pt-20 pt-10 md:w-2/3 lg:w-1/2 md:pt-60 lg:pt-[5rem] xl:pt-[10rem] 2xl:pt-[17rem] lg:pl-[5rem] ">
          <AnimationOnScroll animateIn={"animate__zoomIn"}>
            <h1 class="font-russo font-normal lg:text-[45px] text-[25px] md:text-56 lg:text-56">
              <span class=" dark:text-yellow-200 text-[#E6CB82] font-bold  ">
                {t("herosection.hero_title")}
              </span>
              <br/>
              <span
                class="dark:text-white text-[#000] font-bold ] "
                dangerouslySetInnerHTML={{
                  __html: t("herosection.hero_subtitle", {
                    br1: "<br />",
                  }),
                }}
              ></span>
            </h1>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn={"animate__zoomIn"}>
            <p class="text-[14px] sm:text-[19px] font-light leading-6 font-inter pt-5 dark:text-[white] text-[#262626]">
              {t("herosection.hero_content")}
            </p>
          </AnimationOnScroll>
          <div class="grid grid-cols-5  gap-4  pt-[18rem] md:pt-20 hidden md:grid dark:text-white text-[#000]">
            <div class="flex">
              <div class="flex-1 ">
                <div className="flex justify-center">
                  <img src={img_1} alt="img" />
                </div>
                <p class="text-center">700k Wallets</p>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1">
                <div className="flex justify-center">
                  <img src={img_2} alt="img" />
                </div>
                <p class="text-center">45k+</p>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1">
                <div className="flex justify-center">
                  <img src={img_3} alt="img" />
                </div>
                <p class="text-center">12k+</p>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1">
                <div className="flex justify-center">
                  <img src={img_4} alt="img" />
                </div>
                <p class="text-center">15k+</p>
              </div>
            </div>

            <div class="flex">
              <div class="flex-1">
                <div className="flex justify-center">
                  <img src={img_5} alt="img" width="50px" />
                </div>
                <p class="text-center">20k+</p>
              </div>
            </div>

            {/* <div class="pt-2">
              <img src={img_5} alt="img" />
            </div> */}
          </div>
        </div>
        <div class="grid grid-cols-3 gap-10  pt-[29rem] min-[400px]:pt-[25rem] min-[500px]:pt-[37rem] block md:hidden dark:text-[#ffff] text-[#000]">
          <div>
            <div class="flex">
              <div class="min-h-[40px] min-w-[40px] max-h-[40px] max-w-[40px]">
                <img src={img_1} alt="img" />
              </div>

              <p class="pl-3 text-[12px]">20k Wallets</p>
            </div>
          </div>
          <div>
            <div class="flex">
              <div class="min-h-[40px] min-w-[40px] max-h-[40px] max-w-[40px]">
                <img src={img_3} alt="img" />
              </div>
              <p class="pl-3 text-[12px]">10k+</p>
            </div>
          </div>

          {/* <div class="pt-2">
            <img src={img_5} alt="img" />
          </div> */}

          <div>
            <div class="flex">
              <div class="min-h-[40px] min-w-[40px] max-h-[40px] max-w-[40px]">
                <img src={img_2} alt="img" />
              </div>
              <p class="pl-3 text-[12px]">8k+</p>
            </div>
          </div>
          <div>
            <div class="flex">
              <div class="min-h-[40px] min-w-[40px] max-h-[40px] max-w-[40px]">
                <img src={img_4} alt="img" />
              </div>
              <p class="pl-3 text-[12px]">15k+</p>
            </div>
          </div>
          <div>
            <div class="flex">
              <div class="min-h-[40px] min-w-[40px] max-h-[40px] max-w-[40px]">
                <img src={img_5} alt="img" width="50px" />
              </div>
              <p class="pl-3 text-[12px]">20k+</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default HeroSection;
