import React, { useState } from "react";
import enIcon from "assets/images/flag/en.png";
import krIcon from "assets/images/flag/kr.png";
import viIcon from "assets/images/flag/vn.png";
import dropdownIcon from "assets/icon/dropdown.svg";
import { useTranslation } from "react-i18next";

const SelectTrans = () => {
  const [trans, setTrans] = useState({
    img: enIcon,
    name: "English",
    key: "en",
  });
  const [isOpen, setIsOpen] = useState(false);
  const { i18n } = useTranslation();

  const flags = [
    {
      img: enIcon,
      name: "English",
      key: "en",
    },
    {
      img: krIcon,
      name: "Korea",
      key: "kr",
    },
    {
      img: viIcon,
      name: "Vietnamese",
      key: "vi",
    },
  ];

  const handleChangeLanguage = (lang) => {
    const elem = document.activeElement;
    if (elem) {
      elem?.blur();
    }
    setTrans(lang);
    i18n.changeLanguage(lang.key);
  };

  const handleToggleMenu = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  return (
    <div className="dropdown dropdown-left md:dropdown-right dropdown-bottom">
      <label tabIndex="0" className="cursor-pointer">
        <div className="flex gap-4 items-center" onClick={handleToggleMenu}>
          <img src={trans.img} alt="en" />
          <img src={dropdownIcon} alt="" className="dark:brightness-[100%] brightness-0"/>
        </div>
      </label>
      {isOpen && (
        <ul
          tabIndex="0"
          className="dropdown-content menu p-2 shadow rounded-box w-52 custom-dropdown bg-custom-2 border-1 border-amber-600"
          style={{
            background:
              "linear-gradient(180deg, rgba(88, 74, 37, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)",
            boxShadow: "0px 0px 10px rgba(230, 203, 130, 0.25)",
          }}
        >
          {flags.map((item, index) => (
            <li
              className=""
              onClick={() => {
                handleChangeLanguage(item);
                setIsOpen(false);
              }}
              key={index}
            >
              <div className="flex gap-2 items-center">
                <img src={item.img} alt="en" />
                <span className="font-500 text-white">{item.name}</span>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectTrans;
