import Container from "components/ui/Container";
import smart from "assets/smart.mp4";
import { useTranslation } from "react-i18next";
import { AnimationOnScroll } from "react-animation-on-scroll";
const SmartPhone = () => {
  const { t } = useTranslation();
  return (
    <div class="bg-cover bg-center h-full bg-no-repeat bg-[url('assets/bg_smart.png')] relative bg-[#000]">
      <Container style={{ zIndex: "9" }}>
        <div class=" lg:grid grid-cols-2 pt-[10%]">
          <div class="pb-10 lg:pb-0 md:w-2/3 lg:w-1/2 lg:pl-[5rem] xl:w-full  text-center md:text-md lg:text-lg md:text-left md:absolute md:top-1/2  md:transform md:-translate-y-1/2 ">
            <AnimationOnScroll animateIn={"animate__zoomIn"} delay={100}>
              <h3 class="font-sans text-md font-medium">
                {t("smart.sub_title")}
              </h3>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn={"animate__zoomIn"} delay={200}>
              <h1 class=" text-xl font-bold leading-150  uppercase  text-yellow-200 leading-8 leading-tight md:text-2xl lg:text-3xl ">
                {t("smart.title")}
              </h1>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn={"animate__zoomIn"} delay={300}>
              <p
                class="pt-6 hidden md:block leading-4"
                dangerouslySetInnerHTML={{
                  __html: t("smart.content", {
                    br1: "<br />",
                  }),
                }}
              ></p>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn={"animate__zoomIn"} delay={400}>
              <div className="mt-[30px]">
                <a
                  href="https://www.poolsmobility.com/"
                  target={"_blank"}
                  rel="noreferrer"
                  className="btn rounded-[6px] border-[#FFDA79] border-[1px] bg-transparent min-w-[188px] hover:bg-custom-3 hover:drop-shadow-custom1"
                >
                  Explore more
                </a>
              </div>
            </AnimationOnScroll>
          </div>
          <div class="pt-[10%] md:pt-0 sm:hidden">
            <AnimationOnScroll animateIn={"animate__zoomIn"} delay={50}>
              <video
                src="https://pools-mall.s3.ap-southeast-1.amazonaws.com/videos/POOLS+VERTICAL.mp4"
                autoPlay
                muted
                loop
                playsInline
              />
            </AnimationOnScroll>
          </div>
        </div>
      </Container>
      <div class="hidden sm:block absolute top-0 left-0 w-full h-full">
        <video src={"https://s3.ap-southeast-1.amazonaws.com/mall.poolsmobility.com/smart.mp4"} autoPlay muted loop class="h-full" />
      </div>
    </div>
  );
};
export default SmartPhone;
