import Comunity from "components/comunity/Comunity";
import HeroSection from "components/herosection/HeroSection";
import HowItWork from "components/howitwork/HowItWork";
import Partner from "components/Industry-partner/Partner";
import PoolsChain from "components/Poolschain/PoolsChain";
import ProofOfAuthority from "components/proofofauthority/ProofOfAuthority";
import SmartPhone from "components/smartphone/SmartPhone";
import PoolsEcosystem from "components/poolsecosystem/PoolsEcosystem";
import HomePopup from "components/popup/HomePopup";

import React from "react";

const Home = () => {
  return (
    <>
      <HomePopup/>
      <HeroSection />
      <HowItWork />
      <ProofOfAuthority />
      <PoolsChain />
      <SmartPhone />
      <PoolsEcosystem />
      <Comunity/>
      <Partner/>
    </>
  );
};

export default Home;
