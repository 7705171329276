import Container from "components/ui/Container";
import ImgProof from "assets/img_proof.png";
import ImgProofLight from "assets/img_proof_light.png";
import ImgNot from "assets/not_gold.png";
import ImgRPC from "assets/rpc_gold.png";
import ImgSCA from "assets/sca_gold.png";
import ImgLOW from "assets/low_gold.png";
import ImgCHAIN from "assets/chain_gold.png";
import ImgSEC from "assets/sec_gold.png";
import ImgTran from "assets/tran_gold.png";
import decgold from "assets/img_dec_gold.png";
import { useTranslation } from "react-i18next";
import useColorMode from "hooks/useColorMode";

const ProofOfAuthority = () => {
  const { t, i18n } = useTranslation();
  const [colorMode, setColorMode] = useColorMode();
  return (
    <div class="bg-cover bg-center h-full bg-no-repeat dark:bg-[url('assets/bg_proof.png')] bg-[#ffff]">
      <Container>
        <div class="flex md:absolute top-0 bottom-0 left-0 right-0 justify-center items-center dark:text-[#ffff] text-[#000]">
          <div class="block md:grid grid-cols-3 ">
            <div class="mx-auto pr-6">
              <div class="block md:hidden">
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3">
                  <img src={decgold} alt="" class="pb-5 " />
                  <p class="absolute top-[5%] left-[25%] font-serif font-bold text-base leading-6  text-main_color">
                    {t("proof.dec")}
                  </p>
                </div>
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3 flex justify-end">
                  <img src={ImgSCA} alt="" class=" pb-10" />
                  <p class="absolute top-[10%] left-[30%] md:left-[10%] font-serif font-bold text-base leading-6  text-main_color">
                    {t("proof.sca")}
                  </p>
                </div>
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3">
                  <img src={ImgCHAIN} alt="" class="pb-10" />
                  <p class="absolute top-[-5%] left-[45%] font-serif font-bold text-base leading-6  text-main_color">
                    {t("proof.rbc")}
                  </p>
                </div>
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3 flex justify-end">
                  <img src={ImgLOW} alt="" />
                  <p class="absolute top-[10%] left-[25%] md:left-[5%] font-serif font-bold text-base leading-6  text-main_color">
                    {t("proof.low")}
                  </p>
                </div>
              </div>
              <div class="hidden md:block">
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3 flex justify-end ">
                  <img src={ImgNot} alt="" class="pb-10  " />

                  <p class="absolute top-[10%] left-[35%] md:left-[20%] font-serif font-bold text-base leading-6 text-main_color">
                    {t("proof.not")}
                  </p>
                </div>
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3 flex justify-end">
                  <img src={ImgRPC} alt="" class="pb-10" />
                  <p class="absolute top-[-5%] left-[45%] md:left-[33%] font-serif font-bold text-base leading-6 text-main_color">
                    {t("proof.rbc")}
                  </p>
                </div>
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3 flex justify-end">
                  <img src={ImgSCA} alt="" class=" pb-10" />
                  <p class="absolute top-[10%] left-[30%] md:left-[10%] font-serif font-bold text-base leading-6  text-main_color">
                    {t("proof.sca")}
                  </p>
                </div>
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3 flex justify-end">
                  <img src={ImgLOW} alt="" />
                  <p class="absolute top-[10%] left-[25%] md:left-[5%] font-serif font-bold text-base leading-6  text-main_color">
                    {t("proof.low")}
                  </p>
                </div>
              </div>
            </div>
            <div class="relative pt-[25%] md:pt-[50%] lg:pt-[35%] xl:pt-[25%] pb-[15%]">
              <div class="max-w-[90%] md:max-w-100 mx-auto xl:mt-[20px] md:mt-[-15px] mt-[10px] ">
              <img src={ImgProofLight} alt="" className=" xl:scale-[1.2] scale-[1.1]" />
              </div>
              {i18n.language === "vi" ? (
                <h3 class="absolute  text-yellow-200 top-[38%] left-[14%] md:top-[34%]  min-[912px]:top-[40%]  lg:top-[29%]  text-[100%]  md:text-[73%]  uppercase font-bold font-weight-500 leading-8 leading-tight tracking-wider text-customBG  min-[500px]:text-[100%] md:text-[110%] lg:text-[120%] xl:text-[150%]">
                  {t("proof.title")}
                </h3>
              ) : (
                <h3 class="absolute  text-yellow-200 top-[38%] left-[14%] md:top-[34%] min-[912px]:top-[40%]  lg:top-[29%]  text-[100%] md:text-[95%]  uppercase font-bold font-weight-500 leading-8 leading-tight tracking-wider text-customBG min-[500px]:text-[100%] md:text-[120%] lg:text-[130%] xl:text-[160%]">
                  {t("proof.title")}
                </h3>
              )}
              {i18n.language === "en" ? (
                <p class="absolute top-[47%] left-[14%] text-[80%] md:top-[39%] min-[912px]:top-[45%]  lg:top-[40%] xl:top-[38%]  font-semibold min-[400px]:text-[90%]  md:text-[55%] lg:text-[80%]  xl:text-[100%]  text-white  w-[75%] mx-auto">
                  {t("proof.content")}
                </p>
              ) : (
                <p class="absolute top-[47%] left-[14%] text-[80%] md:top-[39%] min-[912px]:top-[45%]  lg:top-[40%] xl:top-[43%]  font-semibold min-[400px]:text-[90%]  md:text-[55%] lg:text-[80%]  xl:text-[90%]  text-white w-[80%] mx-auto pr-10 ">
                  {" "}
                  {t("proof.content")}
                </p>
              )}{" "}
            </div>
            <div class="mx-auto pr-6">
              <div class="hidden md:block">
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3">
                  <img src={decgold} alt="" class="pb-10" />
                  <p class="absolute top-[5%] left-[25%] font-serif font-bold text-base leading-6  text-main_color">
                    {t("proof.dec")}
                  </p>
                </div>
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3">
                  <img src={ImgCHAIN} alt="" class="pb-10" />
                  <p class="absolute top-[-5%] left-[45%] font-serif font-bold text-base leading-6  text-main_color">
                    {t("proof.chain")}
                  </p>
                </div>
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3">
                  <img src={ImgSEC} alt="" class="pb-10" />
                  <p class="absolute top-[10%] left-[47%] font-serif font-bold text-base leading-6  text-main_color">
                    {t("proof.sec")}
                  </p>
                </div>
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3">
                  <img src={ImgTran} alt="" class="pb-10" />
                  <p class="absolute top-[5%] left-[25%] font-serif font-bold text-base leading-6  text-main_color">
                    {t("proof.tran")}
                  </p>
                </div>
              </div>
              <div class="block md:hidden">
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3 flex justify-end">
                  <img src={ImgRPC} alt="" class="pb-10" />
                  <p class="absolute top-[-5%] left-[40%] md:left-[33%] font-serif font-bold text-base leading-6 text-main_color">
                    {t("proof.chain")}
                  </p>
                </div>
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3">
                  <img src={ImgSEC} alt="" class="pb-10" />
                  <p class="absolute top-[10%] left-[47%] font-serif font-bold text-base leading-6  text-main_color">
                    {t("proof.sec")}
                  </p>
                </div>
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3 flex justify-end ">
                  <img src={ImgNot} alt="" class="pb-10  " />

                  <p class="absolute top-[10%] left-[35%] md:left-[20%] font-serif font-bold text-base leading-6 text-main_color">
                    {t("proof.not")}
                  </p>
                </div>
                <div class="relative opacity-[0.4] hover:opacity-[1] translate-x-3">
                  <img src={ImgTran} alt="" class="pb-10" />
                  <p class="absolute top-[5%] left-[25%] font-serif font-bold text-base leading-6  text-main_color">
                    {t("proof.tran")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default ProofOfAuthority;
