const translations = {
  header: {
    pool_chain: "POOLS Chain",
    dApps: "dApps",
    Community: "Cộng Đồng",
    About: "Về POOLS",
  },
  footer: {
    about_us: "Về Chúng tôi",
    contact: "Contact",
    community: "Cộng đồng",
    explorer: "Ecosystem",
    docs: "Tài liệu",
    job: "Jobs",
    press_kit: "Press kit",
    text1:
      "Nhận các bản cập nhật mới nhất về sản phẩm {{br}} và sự phát triển.",
    text2: "Hãy là người đầu tiên nhận tin từ POOLS",
    send_mail: "Gửi",
    play_ground_text: "CÔNG TY TNHH PLAYGROUND PLANNET PTE",
    copy_right: "Copyright ©",
    reserved: "2023 Pools Phone All Right Reserved",
    enter_mail: "Nhập Email của bạn",
  },
  partner: {
    title: "Đối tác trong ngành",
  },
  community: {
    title: "Tham gia vào cộng đồng chúng tôi",
    content:
      "Hãy khám phá thêm về POOLS, tham gia các cuộc trò chuyện cùng chúng tôi và cân nhắc về phương hướng của hệ sinh thái POOLS.",
  },
  herosection: {
    hero_title: "Mạng Blockchain",
    hero_subtitle: " của tương lai đã sẵn sàng!",
    hero_content:
      "POOLS Chain được thiết kế để tạo ra hệ sinh thái phi tập trung Pools, giúp đơn giản hoá quá trình tạo hợp đồng, triển khai hợp đồng thông minh và sử dụng các dịch vụ.",
  },
  howitwork: {
    title: "Cách hoạt động",
    sub_content_1: "Loại bỏ{{br1}}khả năng {{br1}}bị tấn công mạng",
    sub_content_2: "Hiệu suất{{br1}} năng lượng",
    sub_content_3:
      "Cung cấp nhiều{{br1}}ưu đãi{{br1}} tài chính hơn{{br1}}cho Validator",
    sub_content_4: "Giao dịch {{br1}}nhanh",
    content:
      "Dựa trên công nghệ chuỗi khối,POOLS Chain cho phép mọi người xây dựng các hệ thống lâu dài, phi tập trung, dễ quản lý, nhanh chóng, có thể mở rộng và an toàn. Tạo các mạng chặt chẽ, tinh gọn, phù hợp với yêu cầu của người dùng.",
  },
  proof: {
    title: "BẰNG CHỨNG CỔ PHẦN",
    content:
      "Tốc độ giao dịch được đảm bảo, phí giao dịch thấp, khả năng mở rộng cao đồng thời duy trì tính bảo mật cao. PoS có chi phí giao dịch thấp hơn các thuật toán khác mà vẫn đạt được tốc độ xử lý nhanh.",
    not: "Công chứng",
    rbg: "RPC",
    sca: "Khả năng mở rộng",
    low: "Phi Giao dịch Thấp",
    dec: "Phi Tập Trung",
    chain: "Chuỗi ID",
    sec: "Bảo Mật",
    tran: "Minh Bạch",
  },
  poolsChain: {
    title: "Pools Chain",
    content:
      "POOLS Chain là một hệ thống chuỗi khối hiệu suất cao tương thích với EVM dành cho POOLS Phone và dApps. Nó là một giao thức cho phép người dùng tạo, sở hữu và chạy các cơ sở hạ tầng và ứng dụng phi tập trung.",
    evm_title: "EVM",
    evm_content:
      "Khi phát triển một nền tảng smart contract mới, khả năng tương thích với EVM có thể giúp mở rộng hệ sinh thái Pools và tương tác với các dự án khác.",
    erc20_title: "ERC20",
    erc20_content:
      "ERC20 cho phép xử lý giao dịch cực nhanh để thanh toán, lưu trữ giá trị, giao dịch, tiền tệ trong trò chơi, điểm thưởng và giảm chi phí cho khách hàng...",
    erc721_title: "ERC 721",
    erc721_content:
      "ERC 721 có thể cung cấp một phương pháp hiệu quả để mã hóa nhiều loại lợi ích, trao đổi được vi tính hóa hoặc chính hãng và không tin cậy, trao đổi hoặc quyền sở hữu một phần.",
    erc1155_title: "ERC 1155",
    erc1155_content:
      "ERC 1155 có thể cho phép triển khai NFT, mã thông báo có thể thay thế và mã thông báo bán có thể thay thế, cũng như triển khai nhiều loại mã thông báo trong hợp đồng thông minh có tính bảo mật cao.",
    sol_title: "Solidity",
    sol_content:
      "Solidity là ngôn ngữ lập trình để phát triển các hợp đồng thông minh chạy trên EVM. Smart contract được tích hợp vào công nghệ chuỗi khối, giúp chúng có thể theo dõi, minh bạch, không thể đảo ngược và ngay lập tức.",
  },
  smart: {
    title: "Điện thoại thông minh đầu tiên kiếm được",
    sub_title: "Tất cả 'Tính năng kiếm tiền' tích hợp trong POOLS",
    content:
      " Mã hóa các Cuộc gọi, Tin nhắn SMS, E-mail P2P <br></br>bằng công nghệ POOLS chain dễ dàng trải nghiệm sản phẩm",
  },
  poolsecosystem: {
    title: "Hệ sinh thái POOLS",
    content:
      " Một cộng đồng blockchain được xây dựng một cách cộng tác, với mục đích nâng cao tính toàn diện, tiện lợi và hiệu quả của nó. Tất cả smart contract và tài sản thông minh trong hệ thống của chúng tôi được tạo ra để bảo mật, có thể mở rộng và có thể hoạt động với các chuỗi khối khác.",
    dec: "Phi Tập Trung  <br></br>Sàn giao dịch",
    wall: "Ví <br></br>& Dashboards",
    dap: "dApp <br></br>Store",
    gam: "Gamings",
    soc: " Mạng <br></br> Xã Hội",
    nft: " NFT <br></br>& Marketplace",
  },
};
export default translations;
