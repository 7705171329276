import Container from "components/ui/Container";
import React from "react";
import { useTranslation } from "react-i18next";
import fb_icon from "assets/icons/community/fb.svg";
import twitter from "assets/icons/community/twiter.svg";
import ytb from "assets/icons/community/ytb.svg";
import dis from "assets/icons/community/dis.svg";
import tele from "assets/icons/community/tele.svg";
import mail from "assets/icons/community/mail.svg";
import { AnimationOnScroll } from "react-animation-on-scroll";
const Community = () => {
  const { t } = useTranslation();
  const listSns = [
    {
      icon: fb_icon,
      link: "https://www.facebook.com/profile.php?id=100086429633297",
    },
    {
      icon: ytb,
      link: "https://www.youtube.com/@poolscontact",
    },
    {
      icon: dis,
      link: "https://discord.com/invite/sD4CbW4Ys8",
    },
    {
      icon: tele,
      link: "https://t.me/Poolsclub",
    },
    {
      icon: twitter,
      link: "https://twitter.com/pools_club",
    },
  ];
  return (
    <div className="py-[25px] md:py-[150px] dark:bg-[#000] bg-[#ffff]" id="community">
      <Container
        style={{ minHeight: "100%", maxWidth: "768px", width: "100%" }}
      >
        <div className="w-full flex justify-center mb-[83px] flex-col">
          <AnimationOnScroll animateIn={"animate__zoomIn"}>
            <h3 className="font-[700] text-[36px] dark:text-[#ffffff] text-[#E6CB82] tex text-center">
              {t("community.title")}
            </h3>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn={"animate__zoomIn"} delay={100}>
            <p className="text-center text-[16px] dark:text-[#ffff] text-[#4F4F4F]">{t("community.content")}</p>
          </AnimationOnScroll>
        </div>
        <div class="grid grid-cols-3 gap-10">
          {/* {listSns.map((item, index) => (
            <a href={item.link} target={"_blank"} rel="noreferrer">
              <div
                className=" bg-cover bg-center bg-no-repeat bg-[url('assets/images/community/bg.png')] max-w-[326px] w-full h-[127px] flex justify-center items-center"
                key={index}
              >
                <img src={item.icon} alt="" />
              </div>
            </a>
          ))} */}

          <a href={"https://www.facebook.com/profile.php?id=100086429633297"} target="_blank" rel="noreferrer">
            <div className=" bg-cover bg-center  bg-no-repeat dark:bg-[url('assets/images/community/icon_facebook.png')] bg-[url('assets/images/community/icon_facebook_light.png')] hover:bg-[url('assets/images/community/hover_facebook.png')] max-w-[326px] w-full h-[127px] " />
          </a>

          <a href={"https://www.youtube.com/@poolscontact"} target="_blank" rel="noreferrer">
            <div className=" bg-cover bg-center  bg-no-repeat dark:bg-[url('assets/images/community/icon_youtube.png')] bg-[url('assets/images/community/icon_youtube_light.png')] hover:bg-[url('assets/images/community/hover_youtube.png')] max-w-[326px] w-full h-[127px] " />
          </a>

          <a href={"https://discord.com/invite/sD4CbW4Ys8"} target="_blank" rel="noreferrer">
            <div className=" bg-cover bg-center  bg-no-repeat dark:bg-[url('assets/images/community/icon_disCor.png')] bg-[url('assets/images/community/icon_disCor_light.png')] hover:bg-[url('assets/images/community/hover_discor.png')] max-w-[326px] w-full h-[127px] " />
          </a>

          <a href={"https://t.me/Poolsclub"} target="_blank" rel="noreferrer">
            <div className=" bg-cover bg-center  bg-no-repeat dark:bg-[url('assets/images/community/icon_telegram.png')] bg-[url('assets/images/community/icon_telegram_light.png')] hover:bg-[url('assets/images/community/hover_telegram.png')] max-w-[326px] w-full h-[127px] " />
          </a>

          <a href={"https://twitter.com/pools_club"} target="_blank" rel="noreferrer">
            <div className=" bg-cover bg-center  bg-no-repeat dark:bg-[url('assets/images/community/icon_tw.png')] bg-[url('assets/images/community/icon_tw_light.png')] hover:bg-[url('assets/images/community/hover_tw.png')] max-w-[326px] w-full h-[127px] " />
          </a>

          <a href={"#contact"} rel="noreferrer">
            <div className=" bg-cover bg-center  bg-no-repeat dark:bg-[url('assets/images/community/icon_mail.png')] bg-[url('assets/images/community/icon_mail_light.png')] hover:bg-[url('assets/images/community/hover_mail.png')] dark:hover:brightness-[100%] hover:brightness-[70%]   max-w-[326px] w-full h-[127px] " />
          </a>
        </div>
      </Container>
    </div>
  );
};

export default Community;
