const translations = {
  header: {
    pool_chain: "POOLS Chain",
    dApps: "dApps",
    Community: "Community",
    About: "About",
  },
  footer: {
    about_us: "About us",
    contact: "Contact",
    community: "Community",
    explorer: "Ecosystem",
    docs: "Gitbook Docs",
    job: "Jobs",
    press_kit: "Press kit",
    text1: "Get the latest updates on products {{br}} and developments.",
    text2: "Hear it first from POOLS",
    send_mail: "Send email",
    play_ground_text: "PLAYGROUND PLANET PTE. LTD.",
    copy_right: "Copyright ©",
    reserved: "2023 Pools Phone All Right Reserved",
    enter_mail: "Enter your mail",
  },
  partner: {
    title: "Industry Partners",
  },
  community: {
    title: "Join our community",
    content:
      "Discover more about POOLS, engage with us in conversation, and weigh in on the direction of the POOLS ecosystem.",
  },
  herosection: {
    hero_title: "The Blockchain",
    hero_subtitle: " Network of the {{br1}} Future Available Now",
    hero_content:
      "Pools Chain is designed to assist in the creation of a decentralized Pools ecosystem. It simplifies the creation of contracts, the deployment of smart contracts, and the consumption of services.",
  },
  howitwork: {
    title: "How it works",
    sub_content_1: "Eliminate the {{br1}}possibility of a {{br1}}cyber-attack",
    sub_content_2: "Energy{{br1}}efficiency",
    sub_content_3:
      "Provide more{{br1}}financial{{br1}}incentives for{{br1}}validators",
    sub_content_4: "Fast{{br1}}transaction",
    content:
      "Based on blockchain technology, Pools Chain enables people to build long-lasting, decentralized systems that are simple to manage, fast, scalable, and secure. Create tight, lean networks that are adapted to the requirements of users.",
  },
  proof: {
    title: "PROOF OF STAKE",
    content:
      "Guaranteed transaction speed, low transaction fees,high scalability while maintaining high security. PoS has a lower transaction cost than other algorithms due to its fast transaction processing speed.",
    not: "Notaries",
    rbc: "RPC",
    sca: "Scalability",
    low: "Low transaction fees",
    dec: "Decentralized",
    chain: "Chainid",
    sec: "Security",
    tran: "Transparency",
  },
  poolsChain: {
    title: "Pools Chain",
    content:
      "POOLS Chain is an EVM-compatible high-performance blockchain system for the POOLS Phone, and dApps. It is a protocol that enables users to create, own, and run decentralized infrastructures and applications.",
    evm_title: "EVM",
    evm_content:
      "When developing a new smart contract platform, compatibility with EVM can help to expand the Pools ecosystem and interact with other projects.",
    erc20_title: "ERC20",
    erc20_content:
      "ERC20 empowers extremely fast transaction processing for payment, value storage, transaction, currency in games, reward points, and cost reduction for customers...",
    erc721_title: "ERC 721",
    erc721_content:
      "ERC 721 can provide a powerful method for tokenizing a wide range of benefits, computerized or genuine, and trust-less exchanges, exchanging, or partial proprietorship.",
    erc1155_title: "ERC 1155",
    erc1155_content:
      "ERC 1155 can enable the deployment of NFTs, fungible tokens, and semi-fungible tokens, as well as the deployment of multiple token types in a smart contract with high security.",
    sol_title: "Solidity",
    sol_content:
      "Solidity is a programming language for developing smart contracts that run on EVM. Smart contracts are built into blockchain technology, making them traceable, transparent, irreversible, and instant.",
  },
  smart: {
    title: "THE FIRST SMARTPHONE TO EARN",
    sub_title: "All that 'Phone to Earn' in POOLS",
    content:
      " Encrypted with P2P Calls, SMS message, and e-mail <br></br>using POOLS chain technology to easily experience the products",
  },
  poolsecosystem: {
    title: "POOLS ECOSYSTEM",
    content:
      " We build a blockchain community together and make it more comprehensive, convenient and efficient. All smart property and smart contracts in our ecosystem are designed to be scalable, secure, and interoperable with other blockchains.",
    dec: "Decentralized <br></br>Exchange",
    wall: "Wallet <br></br>& Dashboards",
    dap: "dApp <br></br>Store",
    gam: "Gamings",
    soc: " Social<br></br> Network",
    nft: " NFT <br></br>& Marketplace",
  },
};
export default translations;
