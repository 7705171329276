const translations = {
  header: {
    pool_chain: "POOLS Chain",
    dApps: "dApps",
    Community: "Community",
    About: "About",
  },
  footer: {
    about_us: "소개",
    contact: "Contact",
    community: "커뮤니티",
    explorer: "익스플로러",
    docs: "문서",
    job: "Jobs",
    press_kit: "Press kit",
    text1: "제품 및 개발에 대한 최신 업데이트를 {{br}} 받으십시오.",
    text2: "풀스에서 먼저 들어보세요",
    send_mail: "보내",
    play_ground_text: "플레이그라운드 플래닛 PTE. LTD.",
    copy_right: "Copyright ©",
    reserved: "2023 Pools Phone All Right Reserved",
    enter_mail: "이메일 입력",
  },
  partner: {
    title: "업계 파트너",
  },
  community: {
    title: "우리 커뮤니티에 가입하세요.",
    content:
      "풀스에 대해 더 알아보고, 우리와 대화하고, 풀스 생태계의 방향을 저울질하십시오.",
  },
  herosection: {
    hero_title: "미래의 블록체인",
    hero_subtitle: " 네트워크  {{br1}} 를 지금 사용할 수 있습니다",
    hero_content:
      "Pools Chain는 탈중앙화된 Pools 생태계의 구축을 지원하기 위해 설계되었습니다. 계약 생성, 스마트 계약 배포, 그리고 서비스 소비를 간소화합니다.",
  },
  howitwork: {
    title: "작동 방식",
    sub_content_1: "사이버 공격 가능성 {{br1}}제거",
    sub_content_2: "에너지 {{br1}}효율",
    sub_content_3:
      "검증자에게 {{br1}}더 많은{{br1}}재정적 인센티브 {{br1}}제공",
    sub_content_4: "빠른 {{br1}}트랜잭션",
    content:
      "풀스체인은 블록체인 기술을 기반으로 관리가 간편하고, 빠르고, 확장 가능하며, 안전한 오래 지속되는 탈중앙화된 시스템을 구축할 수 있도록 지원합니다.사용자의 요구사항에 맞게 조정되는 긴밀하고 간결한 네트워크를 구축합니다.",
  },
  proof: {
    title: "지분 증명",
    content:
      "보장된 거래 속도, 낮은 거래 수수료, 높은 확장성을 유지하면서 높은 보안성을 가지고 있습니다. PoS는 빠른 거래 처리 속도로 인해 다른 알고리즘보다 낮은 거래 비용을 갖고 있습니다",
    not: "공증인",
    rbc: "RPC",
    sca: "확장성",
    low: "낮은 트랜잭션 수수료",
    dec: "탈중앙화",
    chain: "체인 ID",
    sec: "보안",
    tran: "투명도",
  },
  poolsChain: {
    title: "Pools Chain",
    content:
      "풀스체인은 풀스폰 및 DAPP를 위한 EVM 호환 고성능 블록 체인 시스템입니다. 사용자가 분산 인프라 및 응용 프로그램을 생성, 소유 및 실행할 수있는 프로토콜입니다.",
    evm_title: "EVM",
    evm_content:
      "새로운 스마트 컨트랙트 플랫폼을 개발할 때 EVM과의 호환성은 풀스 생태계를 확장하고 다른 프로젝트와 상호 작용하는 데 도움이 될 수 있습니다.",
    erc20_title: "ERC20",
    erc20_content:
      "ERC20은 결제, 가치 저장, 거래, 게임 통화, 보상 포인트 및 고객의 비용 절감에 대한 매우 빠른 거래 처리에 힘을 실어줍니다.",
    erc721_title: "ERC 721",
    erc721_content:
      "ERC 721은 광범위한 혜택, 컴퓨터 또는 진실한, 신뢰없는 교환, 교환 또는 부분 소유권을 발표하는 강력한 방법을 제공 할 수 있습니다.",
    erc1155_title: "ERC 1155",
    erc1155_content:
      "ERC 1155는 NFTs, 대체 가능한 토큰 배치를 가능하게 할 뿐만 아니라, 높은 보안성을 갖는 스마트 컨트랙트에서 다수의 토큰 유형의 배치를 가능하게 할 수 있습니다.",
    sol_title: "Solidity",
    sol_content:
      "‎솔리디티는 EVM에서 실행되는 스마트 컨트랙트를 개발하기 위한 프로그래밍 언어입니다. 스마트 컨트랙트는 블록 체인 기술에 내장되어 추적 가능하고 투명하며 돌이킬 수 없고 즉각적입니다.",
  },
  smart: {
    title: "수익을 내는 최초의 스마트폰",
    sub_title: "POOLS의 모든 'Phone-to-earn'",
    content:
      " 풀스체인 기술을 사용하여 P2P 통화, <br></br>SMS 메시지, 이메일을 암호화하여 제품을 쉽게 경험할 수 있습니다.",
  },
  poolsecosystem: {
    title: "풀스 생태계",
    content:
      "우리는 함께 블록체인 커뮤니티를 구축하고 이를 보다 포괄적이고 편리하며 효율적으로 만듭니다. 우리 생태계의 모든 스마트 자산 및 스마트 계약은 확장 가능하고 안전하며 다른 블록체인과 상호 운용할 수 있도록 설계되었습니다.",
    dec: "탈중앙화 <br></br>거래소",
    wall: "지갑 <br></br>& 대시보드",
    dap: "디앱스 <br></br>스토어",
    gam: "게임밍",
    soc: " 소셜 <br></br> 네트워크",
    nft: " NFT  <br></br>및 마켓플레이스",
  },
};
export default translations;
