import SelectTrans from "components/SelectTrans";
import useColorMode from "hooks/useColorMode";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import light from "assets/theme/brightness.png";
import moon from "assets/theme/moon.png";

const Header = () => {
  const { hash } = useLocation();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [colorMode, setColorMode] = useColorMode();

  const menus = [
    {
      name: t("header.pool_chain"),
      href: "https://scan.poolsmobility.com/",
      target: "_blank"

    },
    {
      name: t("header.dApps"),
      href: "#d-apps",
      target: "_self"
    },
    {
      name: t("header.Community"),
      href: "#community",
      target: "_self"
    },
    {
      name: t("header.About"),
      href: "https://www.poolsmobility.com/",
      target: "_self"
    },
  ];

  const isActive = (item) => {
    if (hash === item.href) return true;
  };
  const handleClick = () => {
    const elem = document.activeElement;
    if (elem) {
      elem?.blur();
    }
  };
  const handleToggleMenu = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };
  return (
    <div className="navbar dark:bg-[#000000] bg-[#fff] fixed top-0 z-[99]">
      <div className="max-w-[1394px] w-full mx-auto">
        <div className="w-full flex justify-between items-center">
          <div className="">
            <a className="" href="/">
              <img src="/POOLS_LOGO.svg" alt="logo" />
            </a>
          </div>
          <div className="hidden md:flex items-center justify-between gap-[80px] ">
            {menus.map((item, index) => (
              <div
                className={`  font-[500] text-[16px] leading-[24px]  hover:text-main_color relative
                ${isActive(item)
                    ? ` before:absolute before:bg-gradient-to-r dark:from-yellow-400 from-[#000] to-transparent before:rounded-[5px] before:bottom-0 before:w-full before:h-[2px] dark:text-[#ffff] text-[#000] before:transform-[matrix(1, 0, 0, -1, 0, 0)] transition-[all 0.35s ease-in] `
                    : `dark:text-[#898989] text-[#000]`
                  } `}
                key={index}
              >
                <a target={item.target} href={item.href}>{item.name}</a>
              </div>
            ))}
          </div>

          <div className="flex gap-2 items-center">

          <div className= "bg-[url('assets/theme/moon.png')] dark:bg-[url('assets/theme/brightness.png')] bg-no-repeat w-[20px] h-[20px] cursor-pointer mr-[15px]"
              onClick={() => setColorMode(colorMode === "light" ? "dark" : "light")} >
              {colorMode === "dark"
              ?<img src={light} alt="light" />
              :<img src={moon} alt="moon" />
              }
          </div>
          <SelectTrans />

          <div className="block md:hidden">
              <div onClick={handleToggleMenu} className="dropdown dropdown-end ">
                <label
                  tabIndex={0}
                  className=" btn bg-transparent border-none hover:bg-transparent"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 dark:brightness-[100%] brightness-0"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h7"
                    />
                  </svg>
                </label>
                {isOpen && (
                  <ul
                    tabIndex={0}
                    className=" dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 custom-dropdown border-[1px] border-[#E6CB82]"
                    style={{
                      background:
                        "linear-gradient(180deg, rgba(88, 74, 37, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)",
                      boxShadow: "0px 0px 10px rgba(230, 203, 130, 0.25)",
                    }}
                  >
                    {menus.map((item, index) => (
                      <li onClick={handleClick}>
                        <div
                          className={`font-[500] text-[16px] leading-[24px]  hover:text-main_color `}
                          key={index}
                        >
                          <a href={item.href} className="text-[#fff]">
                            {item.name}
                          </a>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
