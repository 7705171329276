import * as React from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ModalImage from "assets/modal.jpg";
import ModalMobileImage from "assets/modalMobile.jpg";
import useMediaQuery from '@mui/material/useMediaQuery';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  '@media (max-width: 780px)': {
    width: '95%'
  }
};

const dialogTitle = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  position: 'absolute',
  right: 0
}

export default function HomePopup() {
  const matchesMobile = useMediaQuery('(max-width: 780px)');
  const [open, setOpen] = React.useState(true);
  const [popup, setPopup] = React.useState({});
  // const handleOpen = () => setOpen(true);

  const baseUrl = "https://cms.isaacplayground.co.kr";
  // api get popup on server

  React.useEffect(() => {

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'api-key': 'API-36bc2286e67dfd8993722a0d7a8d35c575f7955f',
      }
    };

    fetch(baseUrl + '/api/content/item/poolschainpopup', options)
      .then(response => response.json())
      .then(data => {
        setPopup({
          "mobile_img": baseUrl + "/storage/uploads" + data.mobile_img.path,
          // "mobile_img": "https://cms.isaacplayground.co.kr/storage/uploads//2023/12/12/PoolsChain_Maintenance_11_12_SNS_uid_6577bf6a555b9.png",
          "pc_img": baseUrl + "/storage/uploads" + data.pc_img.path,
          // "pc_img": "https://cms.isaacplayground.co.kr/storage/uploads//2023/12/12/PoolsChain_Maintenance_11_12_Web_uid_6577bf588e24a.png",
          "link": data.link,
          "is_active": data.is_active
        });
        //check active
        setOpen(data.is_active);
      })
      .catch(error => console.error(error));
  }, [])

  
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Modal
        open={open}
        onR
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton sx={dialogTitle} onClick={handleClose}>
            <CloseIcon style={{ color: '#FFFFFF' }} />
          </IconButton>
          <a href={popup.link}>
            <img src={matchesMobile ? popup.mobile_img : popup.pc_img} alt="" />
          </a>
        </Box>
      </Modal>
    </div>
  );
}