import React from "react";

const Container = ({ children, ...props }) => {
  return (
    <div
      {...props}
      className={`container mx-auto max-w-[1394px] w-full  p-[20px] md:p-0 sm:min-h-screen relative`}
    >
      {children}
    </div>
  );
};

export default Container;
