import Container from "components/ui/Container";
import React from "react";
import tel from "assets/images/partner/tel.svg";
import eabr from "assets/images/partner/eqbr.svg";
import twing from "assets/images/partner/twinning.svg";
import singtel from "assets/images/partner/singtel.svg";
import redision from "assets/images/partner/redision.svg";
import vng from "assets/images/partner/vng.svg";
import sotatek from "assets/images/partner/sotatek.svg";
import { useTranslation } from "react-i18next";
const Partner = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-cover bg-center h-full bg-no-repeat dark:bg-[url('assets/bg_partner.png')] bg-[url('assets/bg_partner_light.png')] dark:bg-[#000] bg-[#ffff] py-[25px] md:py-[150px]">
      <Container
        style={{ minHeight: "100%", maxWidth: "768px", width: "100%" }}
      >
        <div className="w-full flex justify-center mb-[83px]">
          <h3 className="font-[700] text-[36px] dark:text-[#ffffff] text-[#E6CB82]">
            {t("partner.title")}
          </h3>
        </div>

        <div className="hidden sm:flex gap-[80px] justify-center items-center mb-4 dark:brightness-[100%] brightness-0">
          <div>
            <img src={sotatek} alt="tel" className="w-100 h-100" />
          </div>

          <div >
            <img src={singtel} alt="singtel" className="w-100 h-100" />
          </div>
          {/* <div>
            <img src={eabr} alt="eabr" className="w-100 h-100" />
          </div>
          <div>
            <img src={twing} alt="twing" className="w-100 h-100" />
          </div> */}
        </div>

        <div className="hidden sm:flex gap-[80px] justify-between items-center mb-4 dark:brightness-[100%] brightness-0">
          <div>
            <img src={tel} alt="singtel" className="w-100 h-100" />
          </div>
          <div className="">
            <img src={redision} alt="redision" className="w-100 h-100" />
          </div>
          {/* <div className="">
            <img src={vng} alt="vng" className="w-100 h-100" />
          </div> */}
        </div>


        <div className="flex sm:hidden justify-between mb-4 dark:brightness-[100%] brightness-0">
          {/* <div className="w-[124px]">
            <img src={tel} alt="tel" className="w-100 h-100" />
          </div>
          <div className="w-[124px]">
            <img src={eabr} alt="eabr" className="w-100 h-100" />
          </div> */}

          <div>
            <img src={sotatek} alt="tel" className="w-100 h-100" />
          </div>

          <div>
            <img src={singtel} alt="singtel" className="w-100 h-100" />
          </div>
        </div>

        <div className="flex sm:hidden justify-between mb-4 dark:brightness-[100%] brightness-0">
          {/* <div className="w-[124px] flex">
            <img src={twing} alt="twing" className="w-100 h-100" />
          </div>
          <div className="w-[124px]">
            <img src={singtel} alt="singtel" className="w-100 h-100" />
          </div> */}

          <div className="">
            <img src={redision} alt="redision" className="w-100 h-100" />
          </div>
          {/* <div className="">
            <img src={vng} alt="vng" className="w-100 h-100" />
          </div> */}
                    <div>
            <img src={tel} alt="singtel" className="pt-8 w-100 h-100" />
          </div>
        </div>

        <div className="flex sm:hidden justify-between mb-4 dark:brightness-[100%] brightness-0">


          {/* <div className="w-[124px]">
            <img src={redision} alt="redision" className="w-100 h-100" />
          </div>
          <div className="w-[124px]">
            <img src={vng} alt="vng" className="w-100 h-100" />
          </div> */}
        </div>

        {/* <div className="flex gap-[80px] justify-center items-center mb-4 pr-[3%]">
          <div className="w-[150px] md:w-[240px]">
            <img src={sotatek} alt="sotatek" className="w-[247px] h-[100px]" />
          </div>
        </div> */}
      </Container>
    </div>
  );
};

export default Partner;
