import { useRoutes } from "react-router-dom";
import routes from "./routes";
import React from "react";

function App() {
  const content = useRoutes(routes);
  return <div className="app">{content}</div>;
}

export default App;
