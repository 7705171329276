import { AnimationOnScroll } from "react-animation-on-scroll";

const Card = ({src,title,content}) => {
 
  return (
   <div>
            <AnimationOnScroll animateIn={"animate__zoomIn"} delay={100}>
              <div className="flex mb-4 items-center px-[5px] sm:pt-[50px] pt-[10px]">
                <div className=" relative z-1 ]">
                  <img src={src} class="max-h-[140px]" alt="img_P"/>
                  <p className="text-[12px] z-20 absolute top-[10px] left-[155px] sm:pr-[30px] pr-auto text-sub-content">
                    <span class="2xl:text-[16px] sm:text-[14px] text-12px font-bold">
                      {title}
                    </span>
                  <br></br> {content}
                  </p>
                </div>
              </div>
            </AnimationOnScroll>
         
    </div>
  );
};
export default Card;
