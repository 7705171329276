import Container from "components/ui/Container";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { useTranslation } from "react-i18next";
import Card from "./Crad";



const PoolsChain = () => {
  const { t, i18n } = useTranslation();

  const dataCard = [
    { src:"/img_P1.png",
      title: t("poolsChain.evm_title"),
      content: t("poolsChain.evm_content")
    },
    { src:"/img_P2.png",
      title: t("poolsChain.erc20_title"),
      content: t("poolsChain.erc20_content")
    },
    { src:"/img_P3.png",
      title: t("poolsChain.erc721_title"),
      content: t("poolsChain.erc721_content")
    },
    { src:"/img_P4.png",
      title: t("poolsChain.erc1155_title"),
      content: t("poolsChain.erc1155_content")
    },
    { src:"/img_P5.png",
      title: t("poolsChain.sol_title"),
      content: t("poolsChain.sol_content")
    },
  ]
  return (
    <div
      class=" bg-cover bg-center h-full bg-no-repeat bg-[url('assets/bg_poolsChain.png')] dark:bg-black bg-[#ffff] w-[90%] mx-auto md:w-full"
      id="pools-chain"
    >
      <Container>
        <AnimationOnScroll animateIn={"animate__zoomIn"}>
          <h1 class=" text-[#E6CB82]  text-[30px] uppercase font-bold font-weight-500 leading-8 leading-tight tracking-wider text-center lg:pt-20">
            {t("poolsChain.title")}
          </h1>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn={"animate__zoomIn"}>
          {i18n.language === "kr" ? (
            <p class="text-center dark:text-gray-400 text-[#000]  text-[11px] md:text-[17px]  pt-5 w-[70%] mx-auto">
              {" "}
              {t("poolsChain.content")}
            </p>
          ) : (
            <p class="text-center dark:text-gray-400 text-[#000] text-[11px] md:text-[17px]  pt-5 w-[70%] mx-auto">
              {" "}
              {t("poolsChain.content")}
            </p>
          )}
        </AnimationOnScroll>
        <div class=" dark:text-white text-[#000] flex justify-center flex-wrap w-full pt-[50px]">
            {dataCard.map((card, index)=>(
              <Card title={card.title} content={card.content} src={card.src} />
            ))}
        </div>
        
      </Container>
    </div>
  );
};
export default PoolsChain;
