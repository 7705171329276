import Container from "components/ui/Container";
import Dec from "assets/des.png";
//import Dap from "assets/DAP.png";
import Gam from "assets/GAM.png";
import Soc from "assets/SOC.png";
import Wall from "assets/WALL.png";
import Dap from "assets/WALL1.png";
import Nft from "assets/NFT.png";
import { useTranslation } from "react-i18next";
import { AnimationOnScroll } from "react-animation-on-scroll";
const PoolsEcosystem = () => {
  const { t } = useTranslation();
  return (
    <div
      class="bg-cover bg-center h-full bg-no-repeat dark:bg-[url('assets/bg_eco.png')] bg-[#ffff] md:relative "
      id="d-apps"
    >
      <Container>
        <div class="md:absolute md:top-1/2 md:left-1 md:transform md:-translate-x-1 md:-translate-y-1/2">
          <AnimationOnScroll animateIn={"animate__zoomIn"}>
            <h1 class="text-[#E6CB82] text-[30px] uppercase font-bold font-weight-500 leading-8 leading-tight tracking-wider text-center ">
              {t("poolsecosystem.title")}
            </h1>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn={"animate__zoomIn"}>
            <p class="text-center pt-5 hidden md:block mx-auto w-[70%] dark:text-white text-[#4F4F4F]">
              {t("poolsecosystem.content")}
            </p>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn={"animate__zoomIn"} delay={100}>
            <div class="flex dark:text-white text-[#1B1C1E]">
              <div class="block md:grid grid-cols-2 grid-rows-3 gap-16 mx-auto place-items-center pt-10 md:pt-20  ">
                <div class="relative hover:cursor-pointer shadow-md">
                  <img src={Dec} class="  py-5 md:px-0 md:py-0 mx-auto" alt=""/>
                  <p
                    class="absolute top-[33%] md:top-[23%] left-[32%] uppercase text-16 md:text-20 font-semibold leading-[0.7]"
                    dangerouslySetInnerHTML={{
                      __html: t("poolsecosystem.dec", {
                        br1: "<br />",
                      }),
                    }}
                  ></p>
                </div>
                <div class="relative hover:cursor-pointer shadow-md">
                  {" "}
                  <img src={Wall} class=" py-5 md:px-0 md:py-0 mx-auto " alt=""/>
                  <p
                    class="absolute top-[33%] md:top-[23%] left-[32%] uppercase text-16 md:text-20 font-semibold leading-[0.7]"
                    dangerouslySetInnerHTML={{
                      __html: t("poolsecosystem.wall", {
                        br1: "<br />",
                      }),
                    }}
                  ></p>
                </div>
                <div class="relative hover:cursor-pointer shadow-md">
                  {" "}
                  <img src={Dap} class=" py-5 md:px-0 md:py-0 mx-auto " alt=""/>{" "}
                  <p
                    class="absolute top-[33%] md:top-[23%] left-[32%] uppercase text-16 md:text-20 font-semibold leading-[0.7]"
                    dangerouslySetInnerHTML={{
                      __html: t("poolsecosystem.dap", {
                        br1: "<br />",
                      }),
                    }}
                  ></p>
                </div>
                <div class="relative hover:cursor-pointer shadow-md">
                  <img src={Gam} class=" py-5 md:px-0 md:py-0 mx-auto" alt=""/>
                  <p
                    class="absolute top-[38%] md:top-[48%] left-[32%] uppercase text-16 md:text-20 font-semibold leading-[0.7]"
                    dangerouslySetInnerHTML={{
                      __html: t("poolsecosystem.gam", {
                        br1: "<br />",
                      }),
                    }}
                  ></p>
                </div>
                <div class="relative hover:cursor-pointer shadow-md">
                  <img src={Soc} class=" py-5 md:px-0 md:py-0 mx-auto" />{" "}
                  <p
                    class="absolute top-[33%] md:top-[23%] left-[32%] uppercase text-16 md:text-20 font-semibold leading-[0.7]"
                    dangerouslySetInnerHTML={{
                      __html: t("poolsecosystem.soc", {
                        br1: "<br />",
                      }),
                    }}
                  ></p>
                </div>
                <div class="relative hover:cursor-pointer shadow-md">
                  <img src={Nft} class=" py-5 md:px-0 md:py-0 mx-auto" />{" "}
                  <p
                    class="absolute top-[33%] md:top-[23%] left-[32%] uppercase text-16 md:text-20 font-semibold leading-[0.7]"
                    dangerouslySetInnerHTML={{
                      __html: t("poolsecosystem.nft", {
                        br1: "<br />",
                      }),
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </AnimationOnScroll>
        </div>
      </Container>
    </div>
  );
};
export default PoolsEcosystem;
